/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/underscore@1.11.0/underscore.min.js
 * - /npm/backbone@1.4.0/backbone.min.js
 * - /npm/backbone.babysitter@0.1.12/lib/backbone.babysitter.min.js
 * - /npm/backbone.wreqr@1.4.0/lib/backbone.wreqr.min.js
 * - /npm/backbone.marionette@2.4.7/lib/core/backbone.marionette.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
